<template>
	<div>
		<CCard>
			<CCardBody class="customer-form">
				<CRow class="d-md-flex">
					<CCol>
						<h5 class="mb-3">
							{{ $t("CustomerTitle.CustomerDetail") }}
						</h5>
					</CCol>

					<CCol class="text-right">
						<template v-if="isUpdate">
							<CButton color="info" @click="createNewUser">{{
								$t("CustomerForm.CreateNewUser")
							}}</CButton>
						</template>
					</CCol>
				</CRow>

				<ValidationObserver ref="form" v-slot="{ handleSubmit }">
					<form @submit.prevent="handleSubmit(submitForm)">
						<div class="custom f-d-col">
							<div class="custom__input w-70-percent">
								<!-- Customer id  -->
								<div v-if="isUpdate" class="form-group position-relative">
									<div class="form-group row">
										<label for="customer-id" class="col-md-4 col-12 col-form-label">{{
											$t("CustomerForm.ID")
										}}</label>
										<div id="customer-id" class="col-12 col-md-8 col-form-label">
											{{ customerForm.customerCode }}<div class="error-mess d-sm-down-none" />
										</div>
									</div>
								</div>

								<!-- Customer type  -->
								<div class="form-group position-relative">
									<ValidationProvider
										ref="customerType"
										v-slot="{ classes, errors }"
										name="customer_type"
										:rules="`required`"
									>
										<div class="form-group row">
											<label for="customerType" class="col-md-4 col-12 col-form-label"
												>{{ $t("CustomerForm.Customer_type") }} <sup class="clor-red">*</sup></label
											>
											<div :class="['col-md-8 col-12', classes]">
												<multiselect
													id="customerType"
													v-model="customerForm.customerType"
													:options="listCustomersType"
													:custom-label="labelCusType"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="false"
													:searchable="false"
													:disabled="isRecentPrEPCustomer"
													@close="checkValidate('customerType')"
												>
													<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
													<template #noResult>{{ $t("DropDown.NoResult") }}</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer code PrEP (if Customer_type === 'PrEP')  -->
								<div v-if="isPrEPCustomer" class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="customer_type_code"
										:rules="`required|max:${maxLength}`"
									>
										<div class="form-group required row">
											<label for="Customer_code_prep" class="col-md-4 col-12 col-form-label"
												>{{ $t("CustomerForm.Customer_code_prep") }}
												<sup class="clor-red">*</sup></label
											>
											<div :class="['col-12 col-md-8', classes]">
												<input
													id="Customer_code_prep"
													v-model.trim="customerForm.customerPrepCode"
													:maxlength="maxLength"
													type="text"
													class="form-control"
													autocomplete="Customer_code_prep"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer classify  -->
								<div v-if="isPrEPCustomer" class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="customer_classify">
										<div class="form-group row">
											<label for="classify" class="col-md-4 col-12 col-form-label">{{
												$t("CustomerForm.classify")
											}}</label>
											<div :class="['col-md-8 col-12', classes]">
												<multiselect
													id="classify"
													v-model="customerForm.customerGroup"
													:options="listClassify"
													:custom-label="labelCusClassify"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:searchable="false"
												>
													<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
													<template #noResult>{{ $t("DropDown.NoResult") }}</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<template v-if="isShowPrep">
									<div class="form-group position-relative">
										<div class="form-group row">
											<label for="customer-prep-date" class="col-md-4 col-12 col-form-label">{{
												$t("CustomerForm.Prep_date")
											}}</label>
											<div id="customer-prep-date" class="col-12 col-md-8 col-form-label">
												{{ customerForm.prepStartDate }}
											</div>
										</div>
									</div>

									<div v-if="customerForm.treatmentStatus" class="form-group position-relative">
										<div class="form-group row">
											<label for="customer-prep-status" class="col-md-4 col-12 col-form-label">{{
												$t("PrEPStatusForm.Status")
											}}</label>
											<div id="customer-prep-status" class="col-12 col-md-8 col-form-label">
												{{ customerForm.treatmentStatus }}
											</div>
										</div>
									</div>
								</template>

								<!-- Customer name  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="customer_full_name"
										:rules="`required|max:${maxLength}`"
									>
										<div class="form-group required row">
											<label for="Customer_fullname" class="col-md-4 col-12 col-form-label"
												>{{ $t("CustomerForm.Name") }} <sup class="clor-red">*</sup></label
											>
											<div :class="['col-12 col-md-8', classes]">
												<input
													id="Customer_fullname"
													v-model.trim="customerForm.fullName"
													:placeholder="$t('CustomerForm.Name')"
													:maxlength="maxLength"
													type="text"
													class="form-control"
													autocomplete="Customer_fullname"
													@keydown="checkValidName($event)"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer CMND  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="customer_cmnd"
										:rules="`max:${maxLength}`"
									>
										<div class="form-group required row">
											<label for="Customer_cmnd" class="col-md-4 col-12 col-form-label">{{
												$t("CustomerForm.CMND")
											}}</label>
											<div :class="['col-12 col-md-8', classes]">
												<input
													id="Customer_cmnd"
													v-model.trim="customerForm.cmnd"
													type="number"
													class="form-control"
													:placeholder="$t('CustomerForm.CMND')"
													autocomplete="Customer_cmnd"
													:maxlength="maxLength"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer Gender  -->
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="customer_gender">
										<div class="form-group row">
											<label for="gender" class="col-md-4 col-12 col-form-label">{{
												$t("CustomerForm.Gender")
											}}</label>
											<div :class="['col-md-8 col-12', classes]">
												<multiselect
													id="gender"
													v-model="customerForm.gender"
													:options="listGender"
													:custom-label="labelCusGender"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:searchable="false"
												>
													<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
													<template #noResult>{{ $t("DropDown.NoResult") }}</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer Year of bith  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="customer_birth_day"
										mode="lazy"
										:rules="`required`"
									>
										<div class="form-group row">
											<label for="birthday" class="col-md-4 col-12 col-form-label"
												>{{ $t("CustomerForm.Birthday") }} <sup class="clor-red">*</sup></label
											>
											<div :class="['col-md-8 col-12', classes]">
												<input
													id="birthday"
													v-model="customerForm.birthYear"
													type="text"
													class="form-control"
													:maxlength="maxlengthBirth"
													@keydown="checkValidPhone($event)"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer Contact  -->
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="customer_contact">
										<div class="form-group row">
											<label for="contact" class="col-md-4 col-12 col-form-label">{{
												$t("CustomerForm.Contact")
											}}</label>
											<div :class="['col-md-8 col-12', classes]">
												<div class="d-flex">
													<multiselect
														id="contact_1"
														v-model="customerForm.contactTool"
														:options="listContactMethod"
														:custom-label="labelContactTool"
														:close-on-select="true"
														:show-labels="false"
														:allow-empty="false"
														:searchable="false"
														:class="['col-md-4 col-4 p-d-l-r-0 text-ellipsis']"
													>
														<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
														<template #noResult>{{ $t("DropDown.NoResult") }}</template>
													</multiselect>
													<input
														id="contact_2"
														v-model.trim="customerForm.contactInfo"
														:maxlength="maxLength"
														type="text"
														class="form-control"
														autocomplete="contact_2"
													/>
												</div>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer phone number -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="customer_phone"
										rules="required|integer"
									>
										<div class="form-group required row">
											<label for="phone" class="col-md-4 col-12 col-form-label"
												>{{ $t("CustomerForm.Phone") }} <sup class="clor-red">*</sup></label
											>
											<div :class="['col-md-8 col-12', classes]">
												<input
													id="phone"
													v-model="customerForm.phone"
													:placeholder="$t('CustomerForm.Phone')"
													:maxlength="phoneMaxLength"
													type="text"
													class="form-control"
													@keydown="checkValidPhone($event)"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer Email  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="customer_email"
										:rules="`email|max:${maxLength}|isEmail`"
										mode="lazy"
									>
										<div class="form-group required row">
											<label for="email" class="col-md-4 col-12 col-form-label">{{
												$t("CustomerForm.Email")
											}}</label>
											<div :class="['col-md-8 col-12', classes]">
												<input
													id="email"
													v-model.trim="customerForm.email"
													type="text"
													class="form-control"
													:placeholder="$t('CustomerForm.Email')"
													autocomplete="email"
													:maxlength="maxLength"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer Address -->
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="customer_address">
										<div class="form-group required row">
											<label for="address" class="col-md-4 col-12 col-form-label"
												>{{ $t("CustomerForm.Address") }}
											</label>
											<div :class="['col-md-8 col-12', classes]">
												<textarea
													id="address"
													v-model.trim="customerForm.address"
													class="form-control"
													rows="3"
													:maxlength="addressMaxLength"
												></textarea>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer Gender focus  -->
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="customer_gender_focus">
										<div class="form-group row">
											<label for="gender_focus" class="col-md-4 col-12 col-form-label">{{
												$t("CustomerForm.Gender_focus")
											}}</label>
											<div :class="['col-md-8 col-12', classes]">
												<multiselect
													id="gender_focus"
													v-model="customerForm.customerObject"
													:options="listCustomerObject"
													:custom-label="labelCusObject"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="true"
													:searchable="false"
												>
													<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
													<template #noResult>{{ $t("DropDown.NoResult") }}</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer Status  -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="customer_status"
										:rules="`required`"
									>
										<div class="form-group row">
											<label for="status" class="col-md-4 col-12 col-form-label"
												>{{ $t("CustomerForm.Status") }} <sup class="clor-red">*</sup></label
											>
											<div :class="['col-md-8 col-12', classes]">
												<multiselect
													id="status"
													v-model="customerForm.status"
													:options="listStatus"
													:custom-label="labelCusStatus"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="false"
													:searchable="false"
													@input="selectStatus"
												>
													<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
													<template #noResult>{{ $t("DropDown.NoResult") }}</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer referrer -->
								<div v-if="isSystemAdmin" class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="customer_phone">
										<div class="form-group required row">
											<label for="note" class="col-md-4 col-12 col-form-label"
												>{{ $t("CustomerForm.Referrer") }}
											</label>
											<div :class="['col-md-8 col-12', classes]">
												<input
													id="referrer"
													v-model.trim="customerForm.referrer"
													type="text"
													class="form-control disable"
													:placeholder="$t('CustomerForm.Referrer')"
													:maxlength="maxLength"
													:disabled="true"
												/>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer app verified (green tick)-->
								<div v-if="isSystemAdmin && verified != null" class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="customer_verified">
										<div class="form-group row">
											<label for="status" class="col-md-4 col-12 col-form-label">
												{{ $t("CustomerForm.CustomerVerified") }}</label
											>
											<div :class="['col-md-8 col-12', classes]">
												<multiselect
													id="verified"
													v-model="verified"
													:options="listVerified"
													:custom-label="labelCusVerified"
													:close-on-select="true"
													:show-labels="false"
													:allow-empty="false"
													:searchable="false"
												>
													<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
													<template #noResult>{{ $t("DropDown.NoResult") }}</template>
												</multiselect>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- Customer Note -->
								<div class="form-group position-relative">
									<ValidationProvider v-slot="{ classes, errors }" name="customer_phone">
										<div class="form-group required row">
											<label for="note" class="col-md-4 col-12 col-form-label"
												>{{ $t("CustomerForm.Note") }}
											</label>
											<div :class="['col-md-8 col-12', classes]">
												<textarea
													id="note"
													v-model.trim="customerForm.note"
													class="form-control"
													rows="3"
												></textarea>
												<div class="error-mess">
													{{ errors[0] }}
												</div>
											</div>
										</div>
									</ValidationProvider>
								</div>

								<!-- SMS -->
								<div class="form-group position-relative">
									<ValidationProvider
										v-slot="{ classes, errors }"
										name="customer_sms"
										rules="required"
									>
										<div class="form-group required row">
											<label for="received-sms" class="col-md-4 col-12 col-form-label">{{
												$t("CustomerForm.ReceivedSMS")
											}}</label>
											<div :class="['col-md-8 col-12', classes]">
												<div class="group-sms">
													<div
														v-for="option in optionSMS"
														:key="option"
														class="form-check group-sms__item"
													>
														<input
															:id="option"
															v-model="configSMS"
															:value="option"
															:disabled="isDisableItemSMS(option)"
															class="form-check-input"
															type="checkbox"
														/>
														<label class="form-check-label" :for="option">{{
															labelSMS(option)
														}}</label>
													</div>
												</div>

												<span class="note-sms">{{ $t("CustomerForm.NoteSMS") }}</span>
												<div class="error-mess">{{ errors[0] }}</div>
											</div>
										</div>
									</ValidationProvider>
								</div>
							</div>
						</div>

						<div class="action text-center">
							<CButton v-bind="{ variant: 'outline' }" color="info" type="submit">{{
								textSubmit
							}}</CButton>
							<CButton
								v-if="isUpdate"
								v-bind="{ variant: 'outline' }"
								color="warning"
								@click="toggleDeleteModal(true)"
								>{{ $t("Button.Delete") }}</CButton
							>
							<CButton v-bind="{ variant: 'outline' }" color="danger" @click="cancelForm">{{
								$t("Button.Exit")
							}}</CButton>
						</div>
					</form>
				</ValidationObserver>
			</CCardBody>
		</CCard>

		<!-- Show modal when change customer status -->
		<CustomModal
			v-if="isShowStatusModal"
			:is-show-submit="false"
			:cancel-color="'outline-custom'"
			:cancel-text="$t('Button.OK')"
			@cancel-modal="confirmStatus"
		>
			<template #content>
				{{ $t("CustomerForm.ConfirmStatus") }}
			</template>
		</CustomModal>

		<!-- Show modal when delete form -->
		<CustomModal
			v-if="isShowDeleteModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="deleteCustomerById"
			@cancel-modal="toggleDeleteModal(false)"
		>
			<template #content>
				<p>{{ $t("CustomerForm.AreYouSure") }}</p>
				<span>{{ $t("CustomerForm.DeleteDescription") }}</span>
			</template>
		</CustomModal>

		<!-- Show modal when cancel if adminForm was changed -->
		<CustomModal
			v-if="isShowDiscardModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="discardChanges"
			@cancel-modal="handleCancelDiscard(false)"
		>
			<template #content>
				<p>{{ $t("Button.DiscardChanges") }}</p>
				<span>{{ $t("Button.DiscardContent") }}</span>
			</template>
		</CustomModal>

		<!-- Show modal when auto create app user -->
		<CustomModal
			v-if="isShowCreateModal"
			:is-show-submit="false"
			:cancel-text="$t('Button.Confirm')"
			@submit-modal="closeCreateUserModal"
		>
			<template #content>
				<span>{{ getCreateUserResult() }}</span>
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { CREATE_APP_ACCOUNT, DELETE_CUSTOMER_BY_ID } from "../store/action-types"
import {
	CHANGE_STATUS_CREATE_MODAL,
	CHANGE_STATUS_CUSTOMER_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
	CHANGE_STATUS_DISCARD_MODAL,
	CREATE_USER_SUCCESS,
	RESET_RECENT_CUSTOMER,
} from "./../store/mutation-types"
import { _GENDER, CUSTOMER_OBJECT, CONTACT_METHOD, CONFIG_SMS } from "@/shared/plugins/constants"
import { createNamespacedHelpers, mapState } from "vuex"
const { mapActions, mapMutations } = createNamespacedHelpers("customers")
import {
	CUSTOMER_TYPE,
	CUSTOMER_CLASSIFY,
	STATUS,
	CUSTOMER_TABS,
	USER_ROLE,
	AUTO_CREATE_USER_RESULT,
	VERIFIED_OPTION,
} from "../../../shared/plugins/constants"
import { isEqual } from "lodash-es"

export default {
	name: "CustomerForm",

	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
		pendingTab: {
			type: String,
			default: CUSTOMER_TABS.INFOR,
		},
	},

	data() {
		return {
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
			phoneMaxLength: process.env.VUE_APP_PHONE_MAX_LENGTH,
			addressMaxLength: process.env.VUE_APP_ADDRESS_MAX_LENGTH,
			maxlengthBirth: process.env.VUE_APP_BIRTH_YEAR,

			originForm: {
				customerCode: null,
				customerType: null,
				customerPrepCode: null,
				customerGroup: null,
				fullName: null,
				phone: null,
				birthYear: null,
				gender: null,
				cmnd: null,
				contactTool: CONTACT_METHOD.EMAIL,
				contactInfo: null,
				address: null,
				customerObject: null,
				status: null,
				note: null,
				verified: null,
				smsRemind: false,
				smsFeedback: false,
			},

			customerForm: {},

			listStatus: [STATUS.ACTIVE, STATUS.INACTIVE],

			listCustomersType: [CUSTOMER_TYPE.PREP, CUSTOMER_TYPE.NORMAL, CUSTOMER_TYPE.UNOFFICIAL],

			listClassify: [CUSTOMER_CLASSIFY.NEW, CUSTOMER_CLASSIFY.OLD],

			listGender: [_GENDER.MALE, _GENDER.FEMALE],

			listContactMethod: [
				CONTACT_METHOD.PHONE,
				CONTACT_METHOD.MESSENGER,
				CONTACT_METHOD.ZALO,
				CONTACT_METHOD.VIBER,
				CONTACT_METHOD.SKYPE,
				CONTACT_METHOD.WHATSAPP,
				CONTACT_METHOD.EMAIL,
			],

			listCustomerObject: [
				CUSTOMER_OBJECT.MSM,
				CUSTOMER_OBJECT.TRANS_WOMAN,
				CUSTOMER_OBJECT.TRANS_MAN,
				CUSTOMER_OBJECT.TCMT,
				CUSTOMER_OBJECT.PROSTITUTE,
				CUSTOMER_OBJECT.HETEROSEXUAL_PARTNER,
				CUSTOMER_OBJECT.OTHER,
			],

			configSMS: [],
			optionSMS: [CONFIG_SMS.REMENDER, CONFIG_SMS.FEEDBACK, CONFIG_SMS.NONE],

			isChangeTab: false,
			currentForm: null,
			createUserStatus: null,
			listVerified: [VERIFIED_OPTION.YES, VERIFIED_OPTION.NO],
			verified: null,
		}
	},

	computed: {
		...mapState("customers", [
			"recentCustomer",
			"isShowStatusModal",
			"isShowDeleteModal",
			"isShowDiscardModal",
			"isShowCreateModal",
			"createUserResult",
		]),
		...mapState("authentication", ["currentUser"]),

		isPrEPCustomer: function () {
			return this.customerForm?.customerType === CUSTOMER_TYPE.PREP
		},

		isRecentPrEPCustomer: function () {
			return this.recentCustomer?.customerType === CUSTOMER_TYPE.PREP
		},

		isShowPrep: function () {
			return this.isUpdate && this.recentCustomer?.customerType === CUSTOMER_TYPE.PREP
		},

		textSubmit: function () {
			return this.isUpdate ? this.$t("Button.SaveChanges") : this.$t("Button.CreateNew")
		},

		isSystemAdmin() {
			const role = this.isMobile ? this.mobileRole : this.currentUser?.role
			return role === USER_ROLE.SYSTEM_ADMIN
		},
	},

	watch: {
		recentCustomer() {
			this.setUpData()
		},

		pendingTab: function (val) {
			if (val !== CUSTOMER_TABS.INFOR && this.isUpdate) {
				if (!isEqual(this.customerForm, this.recentCustomer)) {
					this.isChangeTab = true
					this.toggleDiscardModal(true)
				} else this.$emit("changeTab", val)
			}
		},
	},

	created() {
		if (!this.isUpdate) this.setUpData()
	},

	beforeDestroy() {
		this.RESET_RECENT_CUSTOMER()
	},

	methods: {
		...mapActions({ DELETE_CUSTOMER_BY_ID, CREATE_APP_ACCOUNT }),
		...mapMutations({
			CHANGE_STATUS_CUSTOMER_MODAL,
			CHANGE_STATUS_DELETE_MODAL,
			CHANGE_STATUS_DISCARD_MODAL,
			RESET_RECENT_CUSTOMER,
			CREATE_USER_SUCCESS,
			CHANGE_STATUS_CREATE_MODAL,
		}),

		setUpData() {
			if (this.isUpdate) {
				this.customerForm = { ...this.recentCustomer }
				if (this.recentCustomer != null && this.recentCustomer.verified != null) {
					this.verified = this.recentCustomer.verified ? VERIFIED_OPTION.YES : VERIFIED_OPTION.NO
				}
			} else this.customerForm = { ...this.originForm }

			this.setUpSMS()
		},

		submitForm: async function () {
			const params = {
				customerType: this.customerForm.customerType,
				customerGroup: this.customerForm.customerGroup,
				fullName: this.customerForm.fullName,
				phone: this.customerForm.phone,
				gender: this.customerForm.gender,
				cmnd: this.customerForm.cmnd,
				contactTool: this.customerForm.contactTool,
				contactInfo: this.customerForm.contactInfo,
				address: this.customerForm.address,
				customerObject: this.customerForm.customerObject,
				status: this.customerForm.status,
				note: this.customerForm.note,
				birthYear: this.customerForm.birthYear,
				email: this.customerForm.email,
				smsRemind: this.configSMS.includes(CONFIG_SMS.REMENDER),
				smsFeedback: this.configSMS.includes(CONFIG_SMS.FEEDBACK),
			}
			if (this.verified != null) {
				params["verified"] = this.verified === VERIFIED_OPTION.YES
			}
			if (this.customerForm.customerType === CUSTOMER_TYPE.PREP) {
				params["customerPrepCode"] = this.customerForm.customerPrepCode
			}

			this.$emit("submit", params)
		},

		cancelForm: function () {
			let isEq
			if (this.isUpdate) {
				isEq = isEqual(this.customerForm, this.recentCustomer)
			} else isEq = isEqual(this.customerForm, this.originForm)

			if (!isEq) this.toggleDiscardModal(true)
			else this.$emit("cancel")
		},

		setUpSMS() {
			if (this.isUpdate) {
				if (!this.customerForm.smsRemind && !this.customerForm.smsFeedback)
					this.configSMS = [CONFIG_SMS.NONE]
				else {
					this.customerForm.smsRemind && this.configSMS.push(CONFIG_SMS.REMENDER)
					this.customerForm.smsFeedback && this.configSMS.push(CONFIG_SMS.FEEDBACK)
				}
			} else {
				this.configSMS = [CONFIG_SMS.REMENDER, CONFIG_SMS.FEEDBACK]
			}
		},

		discardChanges() {
			this.toggleDiscardModal(false)

			if (this.isChangeTab) this.$emit("changeTab", this.pendingTab)
			else this.$emit("cancel")
		},

		handleCancelDiscard(status = false) {
			if (this.isChangeTab) {
				this.isChangeTab = false
				this.$emit("cancel-change-tab")
			}

			this.toggleDiscardModal(status)
		},

		toggleDiscardModal(status = false) {
			this.CHANGE_STATUS_DISCARD_MODAL(status)
		},

		deleteCustomerById() {
			this.$emit("delAccount")
			this.toggleDeleteModal(false)
		},

		toggleDeleteModal(status = false) {
			this.CHANGE_STATUS_DELETE_MODAL(status)
		},

		confirmStatus() {
			this.CHANGE_STATUS_CUSTOMER_MODAL(false)
		},

		selectStatus(val) {
			if (val === STATUS.INACTIVE) this.CHANGE_STATUS_CUSTOMER_MODAL(true)
		},

		labelCusType(value) {
			return {
				[CUSTOMER_TYPE.PREP]: this.$t("CustomersType.PrEP"),
				[CUSTOMER_TYPE.NORMAL]: this.$t("CustomersType.Normal"),
				[CUSTOMER_TYPE.UNOFFICIAL]: this.$t("CustomersType.Unofficial"),
			}[value]
		},

		labelCusClassify(value) {
			return {
				[CUSTOMER_CLASSIFY.NEW]: this.$t("CustomersClasstify.NEW"),
				[CUSTOMER_CLASSIFY.OLD]: this.$t("CustomersClasstify.OLD"),
			}[value]
		},

		labelCusGender(value) {
			return {
				[_GENDER.MALE]: this.$t("CustomerForm.Male"),
				[_GENDER.FEMALE]: this.$t("CustomerForm.Female"),
			}[value]
		},

		labelContactTool(value) {
			return {
				[CONTACT_METHOD.PHONE]: "Phone",
				[CONTACT_METHOD.MESSENGER]: "Messenger",
				[CONTACT_METHOD.ZALO]: "Zalo",
				[CONTACT_METHOD.VIBER]: "Viber",
				[CONTACT_METHOD.SKYPE]: "Skype",
				[CONTACT_METHOD.WHATSAPP]: "Whatsapp",
				[CONTACT_METHOD.EMAIL]: "Email",
			}[value]
		},

		labelCusObject(value) {
			return {
				[CUSTOMER_OBJECT.MSM]: this.$t("CustomerObject.MSM"),
				[CUSTOMER_OBJECT.TRANS_WOMAN]: this.$t("CustomerObject.TRANS_WOMAN"),
				[CUSTOMER_OBJECT.TRANS_MAN]: this.$t("CustomerObject.TRANS_MAN"),
				[CUSTOMER_OBJECT.TCMT]: this.$t("CustomerObject.TCMT"),
				[CUSTOMER_OBJECT.PROSTITUTE]: this.$t("CustomerObject.PROSTITUTE"),
				[CUSTOMER_OBJECT.HETEROSEXUAL_PARTNER]: this.$t("CustomerObject.HETEROSEXUAL_PARTNER"),
				[CUSTOMER_OBJECT.OTHER]: this.$t("CustomerObject.OTHER"),
			}[value]
		},

		labelCusStatus(value) {
			return {
				[STATUS.ACTIVE]: this.$t("Status.Active"),
				[STATUS.INACTIVE]: this.$t("Status.Inactive"),
			}[value]
		},

		labelSMS(value) {
			return {
				[CONFIG_SMS.REMENDER]: this.$t("CustomerForm.ReminderAppt"),
				[CONFIG_SMS.FEEDBACK]: this.$t("CustomerForm.ServiceRatingPrep"),
				[CONFIG_SMS.NONE]: this.$t("CustomerForm.DontNeed"),
			}[value]
		},

		isDisableItemSMS(option) {
			if (!this.configSMS.length) return false

			const sms = [...this.configSMS]
			// Or select None
			// Or select REMENDER and FEEDBACK
			if (
				(option === CONFIG_SMS.NONE && !sms.includes(CONFIG_SMS.NONE)) ||
				(option !== CONFIG_SMS.NONE && sms.includes(CONFIG_SMS.NONE))
			)
				return true
		},

		checkValidate(field) {
			if (this.$refs[field]) this.$refs[field].validate()
		},

		createNewUser: async function () {
			await this.CREATE_APP_ACCOUNT(this.recentCustomer.id)
		},

		closeCreateUserModal: function () {
			this.CHANGE_STATUS_CREATE_MODAL(false)
		},

		getCreateUserResult() {
			if (this.createUserResult === AUTO_CREATE_USER_RESULT.USER_HAS_BEEN_CREATED) {
				return this.$t("ErrorMessage.UserAlreadyCreated")
			} else if (this.createUserResult === AUTO_CREATE_USER_RESULT.PHONE_IS_EXISTED) {
				return this.$t("ErrorMessage.PhoneUsed")
			} else {
				return this.$t("CustomerForm.CreateUserSuccess")
			}
		},

		labelCusVerified(value) {
			return {
				[VERIFIED_OPTION.YES]: this.$t("CustomerForm.Yes"),
				[VERIFIED_OPTION.NO]: this.$t("CustomerForm.No"),
			}[value]
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";

.f-d-col {
	flex-direction: column;
}
.clor-red {
	color: red;
}
.w-70-percent {
	width: 70%;
}
.d-flex {
	display: flex;
}
.p-d-l-r-0 {
	padding-left: 0;
	padding-right: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

span.error-msg {
	color: red;
	margin-top: 5px;
}

.action {
	display: flex;
	justify-content: space-between;

	@media (min-width: $xxs) {
		justify-content: center;

		.btn + .btn {
			margin-left: 40px;
		}
	}
}

.group-sms {
	display: flex;
	flex-wrap: wrap;

	&__item {
		margin-right: 18px;
		&:not(:last-child) {
			margin-bottom: 4px;
		}

		@media (min-width: $xxs) {
			margin-bottom: 0 !important;
		}
	}
}

.note-sms {
	font-size: 12px;
	opacity: 0.9;
}
</style>
